import { round10 } from "_react/shared/_helpers/numbers";

// Seasonal Arsenal Scores Helpers

export const formatRa9 = (input?: number | null, nullFillerText = "") => {
	return round10(input, -2) ?? nullFillerText;
};

export const format2080Grade = (input?: number | null, nullFillerText = "") => {
	return input != null ? Math.round(input).toString() : nullFillerText;
};

export const formatReleaseVelo = (input?: number | null, nullFillerText = "") => {
	return round10(input, -1) ?? nullFillerText;
};

export const formatSpinRate = (input?: number | null, nullFillerText = "") => {
	return round10(input, 0) ?? nullFillerText;
};

export const formatSpinEfficiency = (input?: number | null, nullFillerText = "") => {
	return input != null ? `${round10(input, -1)}%` : nullFillerText;
};

export const formatBreak = (input?: number | null, nullFillerText = "") => {
	return round10(input, -1) ?? nullFillerText;
};

export const formatRelease = (input?: number | null, nullFillerText = "") => {
	return round10(input, -1) ?? nullFillerText;
};

// Adjusts AMA values to be consistent with PRO values
export const getBatSpeedInferredMetalBatAdjustment = (batSpeedInferred?: number | null) => {
	return batSpeedInferred != null ? batSpeedInferred - 3 : batSpeedInferred;
};
