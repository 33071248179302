import React from "react";
import { Text } from "@chakra-ui/react";

import {
	TOOLTIP_ATTACK_ANGLE_INFERRED,
	TOOLTIP_BAT_SPEED_INFERRED,
	TOOLTIP_BAT_TO_BALL,
	TOOLTIP_COMPOSITE_HITTER_GRADE,
	TOOLTIP_SWING_DECISIONS
} from "_react/shared/_constants/tooltips";
import { round10 } from "_react/shared/_helpers/numbers";
import { getLevelDisplayFromLevels, getAmaTeamDisplay } from "_react/shared/_helpers/stats";
import { TMetricReliabilityThresholds } from "_react/shared/data_models/metric_reliability/_helpers";
import { THROWS_OVERALL } from "_react/shared/data_models/seasonal_grades/_constants";
import { format2080Grade } from "_react/shared/data_models/seasonal_grades/_helpers";
import { VALUE_TYPE_NUMBER, VALUE_TYPE_STRING } from "_react/shared/ui/presentation/components/Table/_constants";
import { TColumn, TValueType } from "_react/shared/ui/presentation/components/Table/_types";
import SampleSizeField from "_react/shared/ui/presentation/components/Table/SampleSizeField";

import {
	getLevelsFromRow,
	getMeetsReliabilityThreshold
} from "_react/shared/ui/data/tables/AmaPositionPlayerFoundationalSkillsTable/_helpers";
import { TAmaPositionPlayerFoundationalSkillsRow } from "_react/shared/ui/data/tables/AmaPositionPlayerFoundationalSkillsTable/_types";

// Non-Column Constants

export const NUM_DISPLAY_SEASONS = 3;

// Table Columns

const NULL_FILLER_TEXT = "";

const SEASON_COLUMN = {
	id: "season",
	value: "season",
	label: "Season",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TAmaPositionPlayerFoundationalSkillsRow) => row.combinedPlayerSeasonHitterGradesData.season
};

const TEAM_COLUMN = {
	id: "team",
	value: "team",
	label: "Team",
	isMobile: true,
	valueType: VALUE_TYPE_STRING as TValueType,
	getValueFunction: (row: TAmaPositionPlayerFoundationalSkillsRow) => {
		const childRowCount = row.childData?.length;
		// Child Rows or rows with no nested data
		if ("team" in row.combinedPlayerSeasonHitterGradesData)
			return getAmaTeamDisplay(row.combinedPlayerSeasonHitterGradesData.team);
		// Parent Rows with nested data
		if (childRowCount && childRowCount > 1) return <Text>{`${childRowCount} teams`}</Text>;
		return <Text>{NULL_FILLER_TEXT}</Text>;
	}
};

const LEVEL_COLUMN = {
	id: "level",
	value: "level",
	label: "Level",
	isMobile: true,
	valueType: VALUE_TYPE_STRING as TValueType,
	getValueFunction: (row: TAmaPositionPlayerFoundationalSkillsRow) =>
		getLevelDisplayFromLevels(getLevelsFromRow(row), NULL_FILLER_TEXT, true),
	getSortValueFunction: (row: TAmaPositionPlayerFoundationalSkillsRow) => {
		// Child Rows
		if ("team" in row.combinedPlayerSeasonHitterGradesData)
			return row.combinedPlayerSeasonHitterGradesData.team?.levelRel?.sortOrder ?? Number.MAX_SAFE_INTEGER;
		// Parent Rows with nested data - highest level if multiple levels
		if (row.childData && row.childData.length > 1)
			return Math.min(
				...row.childData.map((childRow: TAmaPositionPlayerFoundationalSkillsRow) => {
					if ("team" in childRow.combinedPlayerSeasonHitterGradesData)
						return (
							childRow.combinedPlayerSeasonHitterGradesData.team?.levelRel?.sortOrder ??
							Number.MAX_SAFE_INTEGER
						);
					return Number.MAX_SAFE_INTEGER;
				})
			);
		return NULL_FILLER_TEXT;
	}
};

const THROWS_COLUMN = {
	id: "throws",
	value: "throws",
	label: "Throws",
	isMobile: true,
	valueType: VALUE_TYPE_STRING as TValueType,
	getValueFunction: (row: TAmaPositionPlayerFoundationalSkillsRow) =>
		row.combinedPlayerSeasonHitterGradesData.throws ?? NULL_FILLER_TEXT
};

const PA_COLUMN = (thresholds?: { [index: string]: TMetricReliabilityThresholds }) => {
	return {
		id: "pa",
		value: "pa",
		label: "PA",
		valueType: VALUE_TYPE_NUMBER as TValueType,
		getValueFunction: (row: TAmaPositionPlayerFoundationalSkillsRow) => {
			const thresholdInfo = getMeetsReliabilityThreshold(row, thresholds);
			return (
				<SampleSizeField
					sampleSize={row.combinedPlayerSeasonHitterGradesData.pa ?? NULL_FILLER_TEXT}
					tooltipText={thresholdInfo.tooltip}
					isDisplayWarningSymbol={!thresholdInfo.meetsAllThresholds}
				/>
			);
		}
	};
};

const BAT_SPEED_COLUMN = {
	id: "batSpeed",
	value: "batSpeed",
	label: "Bat Speed",
	tooltip: TOOLTIP_BAT_SPEED_INFERRED,
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TAmaPositionPlayerFoundationalSkillsRow) =>
		format2080Grade(row.combinedPlayerSeasonHitterGradesData.inferredBatSpeedGrade, NULL_FILLER_TEXT)
};

const BAT_TO_BALL_COLUMN = {
	id: "batToBall",
	value: "batToBall",
	label: "Bat to Ball",
	tooltip: TOOLTIP_BAT_TO_BALL,
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TAmaPositionPlayerFoundationalSkillsRow) =>
		format2080Grade(row.combinedPlayerSeasonHitterGradesData.batToBallGrade, NULL_FILLER_TEXT)
};

const SWING_DECISIONS_COLUMN = {
	id: "swingDecisions",
	value: "swingDecisions",
	label: "Swing Decisions",
	tooltip: TOOLTIP_SWING_DECISIONS,
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TAmaPositionPlayerFoundationalSkillsRow) =>
		format2080Grade(row.combinedPlayerSeasonHitterGradesData.swingDecisionsGrade, NULL_FILLER_TEXT)
};

const COMPOSITE_COLUMN = {
	id: "composite",
	value: "composite",
	label: "Composite",
	tooltip: TOOLTIP_COMPOSITE_HITTER_GRADE,
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TAmaPositionPlayerFoundationalSkillsRow) =>
		format2080Grade(row.combinedPlayerSeasonHitterGradesData.compositeGrade, NULL_FILLER_TEXT)
};

const VERTICAL_ATTACK_ANGLE_COLUMN = {
	id: "vaa",
	value: "vaa",
	label: "VAA",
	tooltip: TOOLTIP_ATTACK_ANGLE_INFERRED,
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TAmaPositionPlayerFoundationalSkillsRow) =>
		round10(row.combinedPlayerSeasonHitterGradesData.inferredAttackAngleVertical, -1) ?? NULL_FILLER_TEXT
};

export const getAmaPositionPlayerFoundationalSkillsColumns = (
	throwsFilter: string,
	thresholds?: { [index: string]: TMetricReliabilityThresholds }
): Array<TColumn<TAmaPositionPlayerFoundationalSkillsRow, keyof TAmaPositionPlayerFoundationalSkillsRow>> => {
	const columns: Array<TColumn<
		TAmaPositionPlayerFoundationalSkillsRow,
		keyof TAmaPositionPlayerFoundationalSkillsRow
	>> = [SEASON_COLUMN, TEAM_COLUMN, LEVEL_COLUMN];
	if (throwsFilter !== THROWS_OVERALL) {
		columns.push(THROWS_COLUMN);
	}
	return columns.concat([
		PA_COLUMN(thresholds),
		BAT_SPEED_COLUMN,
		BAT_TO_BALL_COLUMN,
		SWING_DECISIONS_COLUMN,
		COMPOSITE_COLUMN,
		VERTICAL_ATTACK_ANGLE_COLUMN
	]);
};
