import React from "react";

import { getAmaLevelDisplayText, getAmaTeamDisplay } from "_react/shared/_helpers/stats";
import { formatCollegesplitsPercentage } from "_react/shared/data_models/ama_stats/_helpers";
import { formatThreeDecimal } from "_react/shared/data_models/stats/_helpers";
import { TColumn } from "_react/shared/ui/presentation/components/Table/_types";
import { VALUE_TYPE_NUMBER, VALUE_TYPE_STRING } from "_react/shared/ui/presentation/components/Table/_constants";
import { TValueType } from "_react/shared/ui/presentation/components/Table/_types";
import { THROWS_OVERALL } from "_react/shared/data_models/seasonal_grades/_constants";
import TeamLevelBadge from "_react/shared/ui/presentation/components/TeamLevelBadge/TeamLevelBadge";

import { ICollegeSplitsPlayerBattingStatsByTeam } from "_react/shared/data_models/ama_stats/_types";

// Non-Column Constants

export const NUM_DISPLAY_SEASONS = 3;

// Table Columns

const NULL_FILLER_TEXT = "";

const SEASON_COLUMN = {
	id: "season",
	value: "season",
	label: "Season",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: ICollegeSplitsPlayerBattingStatsByTeam) => row.season
};

const TEAM_COLUMN = {
	id: "team",
	value: "team",
	label: "Team",
	isMobile: true,
	valueType: VALUE_TYPE_STRING as TValueType,
	getValueFunction: (row: ICollegeSplitsPlayerBattingStatsByTeam) => getAmaTeamDisplay(row.team),
	getSortValueFunction: (row: ICollegeSplitsPlayerBattingStatsByTeam) =>
		`${row.team?.nameDisplay ?? row.team?.name}-${row.teamId}`
};

const LEVEL_COLUMN = {
	id: "level",
	value: "level",
	label: "Level",
	isMobile: true,
	valueType: VALUE_TYPE_STRING as TValueType,
	getValueFunction: (row: ICollegeSplitsPlayerBattingStatsByTeam) => (
		<TeamLevelBadge
			level={row.team?.level}
			displayName={getAmaLevelDisplayText(row.team?.level)}
			nullFillerText={NULL_FILLER_TEXT}
		/>
	),
	getSortValueFunction: (row: ICollegeSplitsPlayerBattingStatsByTeam) =>
		row.team?.levelRel?.sortOrder ?? Number.MAX_SAFE_INTEGER
};

const THROWS_COLUMN = {
	id: "throws",
	value: "throws",
	label: "Throws",
	isMobile: true,
	valueType: VALUE_TYPE_STRING as TValueType,
	getValueFunction: (row: ICollegeSplitsPlayerBattingStatsByTeam) => row.throws ?? NULL_FILLER_TEXT
};

const G_COLUMN = (throwsFilter: string) => {
	return {
		id: "games",
		value: "g",
		label: "G",
		isMobile: true,
		valueType: VALUE_TYPE_NUMBER as TValueType,
		// CS always has 0 games when filtering by handedness so need to show null there instead
		getValueFunction: (row: ICollegeSplitsPlayerBattingStatsByTeam) =>
			throwsFilter !== THROWS_OVERALL ? NULL_FILLER_TEXT : row.g ?? NULL_FILLER_TEXT
	};
};

const PA_COLUMN = {
	id: "pa",
	value: "pa",
	label: "PA",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: ICollegeSplitsPlayerBattingStatsByTeam) => row.pa ?? NULL_FILLER_TEXT
};

const HR_COLUMN = {
	id: "hr",
	value: "hr",
	label: "HR",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: ICollegeSplitsPlayerBattingStatsByTeam) => row.hr ?? NULL_FILLER_TEXT
};

const BA_COLUMN = {
	id: "ba",
	value: "ba",
	label: "BA",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: ICollegeSplitsPlayerBattingStatsByTeam) => formatThreeDecimal(row.ba) ?? NULL_FILLER_TEXT,
	getSortValueFunction: (row: ICollegeSplitsPlayerBattingStatsByTeam) => row.ba
};

const OBP_COLUMN = {
	id: "obp",
	value: "obp",
	label: "OBP",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: ICollegeSplitsPlayerBattingStatsByTeam) => formatThreeDecimal(row.obp) ?? NULL_FILLER_TEXT,
	getSortValueFunction: (row: ICollegeSplitsPlayerBattingStatsByTeam) => row.obp
};

const SLG_COLUMN = {
	id: "slg",
	value: "slg",
	label: "SLG",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: ICollegeSplitsPlayerBattingStatsByTeam) => formatThreeDecimal(row.slg) ?? NULL_FILLER_TEXT,
	getSortValueFunction: (row: ICollegeSplitsPlayerBattingStatsByTeam) => row.slg
};

const OPS_COLUMN = {
	id: "ops",
	value: "ops",
	label: "OPS",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: ICollegeSplitsPlayerBattingStatsByTeam) => formatThreeDecimal(row.ops) ?? NULL_FILLER_TEXT,
	getSortValueFunction: (row: ICollegeSplitsPlayerBattingStatsByTeam) => row.ops
};

const BB_PCT_COLUMN = {
	id: "bbPct",
	value: "bbPct",
	label: "BB%",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: ICollegeSplitsPlayerBattingStatsByTeam) => formatCollegesplitsPercentage(row.bbPct),
	getSortValueFunction: (row: ICollegeSplitsPlayerBattingStatsByTeam) => row.bbPct
};

const HBP_PCT_COLUMN = {
	id: "hbpPct",
	value: "hbpPct",
	label: "HBP%",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: ICollegeSplitsPlayerBattingStatsByTeam) => formatCollegesplitsPercentage(row.hbpPct),
	getSortValueFunction: (row: ICollegeSplitsPlayerBattingStatsByTeam) => row.hbpPct
};

const K_PCT_COLUMN = {
	id: "kPct",
	value: "kPct",
	label: "K%",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: ICollegeSplitsPlayerBattingStatsByTeam) => formatCollegesplitsPercentage(row.kPct),
	getSortValueFunction: (row: ICollegeSplitsPlayerBattingStatsByTeam) => row.kPct
};

const ISO_COLUMN = {
	id: "iso",
	value: "iso",
	label: "ISO",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: ICollegeSplitsPlayerBattingStatsByTeam) => formatThreeDecimal(row.iso) ?? NULL_FILLER_TEXT,
	getSortValueFunction: (row: ICollegeSplitsPlayerBattingStatsByTeam) => row.iso
};

const BABIP_COLUMN = {
	id: "babip",
	value: "babip",
	label: "BABIP",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: ICollegeSplitsPlayerBattingStatsByTeam) =>
		formatThreeDecimal(row.babip) ?? NULL_FILLER_TEXT,
	getSortValueFunction: (row: ICollegeSplitsPlayerBattingStatsByTeam) => row.babip ?? NULL_FILLER_TEXT
};

const SB_COLUMN = {
	id: "sb",
	value: "sb",
	label: "SB",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: ICollegeSplitsPlayerBattingStatsByTeam) => row.sb ?? NULL_FILLER_TEXT
};

const CS_COLUMN = {
	id: "cs",
	value: "cs",
	label: "CS",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: ICollegeSplitsPlayerBattingStatsByTeam) => row.cs ?? NULL_FILLER_TEXT
};

export const getPositionPlayerCollegesplitsColumns = (throwsFilter: string) => {
	const columns: Array<TColumn<
		ICollegeSplitsPlayerBattingStatsByTeam,
		keyof ICollegeSplitsPlayerBattingStatsByTeam
	>> = [SEASON_COLUMN, TEAM_COLUMN, LEVEL_COLUMN];
	if (throwsFilter !== THROWS_OVERALL) {
		columns.push(THROWS_COLUMN);
	}
	return columns.concat([
		G_COLUMN(throwsFilter),
		PA_COLUMN,
		HR_COLUMN,
		BA_COLUMN,
		OBP_COLUMN,
		SLG_COLUMN,
		OPS_COLUMN,
		BB_PCT_COLUMN,
		HBP_PCT_COLUMN,
		K_PCT_COLUMN,
		ISO_COLUMN,
		BABIP_COLUMN,
		SB_COLUMN,
		CS_COLUMN
	]);
};
