// Report Type
export const TYPE_FULL = "FULL";
export const REPORT_TYPES = [TYPE_FULL];

// Report Designation
export const DESIGNATION_HIT = "HIT";
export const DESIGNATION_PITCH = "PITCH";

// Report Field Types
export const FIELD_TYPE_TEXT = "text";
export const FIELD_TYPE_DATE = "date";
export const FIELD_TYPE_NUMERIC = "numeric";
export const FIELD_TYPE_SELECT = "select";
export const FIELD_TYPE_COLLECTION = "collection";
export const FIELD_TYPE_CHECKBOX = "checkbox";
export const FIELD_TYPE_CUSTOM = "custom";
export const FIELD_TYPE_READONLY = "readonly";

// Report Field Variants
export const FIELD_VARIANT_LONG = "long"; // For long-text fields
export const FIELD_VARIANT_WITH_TIME = "withTime"; // For date fields
export const FIELD_VARIANT_FULL_WIDTH = "fullWidth"; // For button select fields
export const FIELD_VARIANT_VERTICAL = "vertical"; // For button select fields
export const FIELD_VARIANT_DROPDOWN = "dropdown"; // For select fields
export const FIELD_VARIANT_BUTTON = "button"; // For numeric/select fields
export const FIELD_VARIANT_SLIDER = "slider"; // For numeric/select fields
export const FIELD_VARIANT_INPUT = "input"; // For numeric fields
export const FIELD_VARIANT_RANGE = "range"; // For numeric fields
export const FIELD_VARIANT_COLLAPSIBLE = "collapsible"; // For collection fields
export const FIELD_VARIANT_FIRST_SENTENCE_BOLD = "firstSentenceBold"; // For text fields
// Custom Field Variants
export const FIELD_VARIANT_TYPE_PROBABILITY = "probability";
export const FIELD_VARIANT_TYPE_OFP = "ofp";
export const FIELD_VARIANT_TYPE_TEAM = "team";
export const FIELD_VARIANT_TYPE_ORG = "org";
export const FIELD_VARIANT_TYPE_SNAPSHOT = "snapshot";

// Pitch Categorization
export const PITCH_GROUP_FASTBALL = "FB";
export const PITCH_GROUP_SECONDARY = "SEC";
export const PITCH_GROUP_FASTBALL_PITCHES = ["FF", "SI"];

// Custom Record Statuses
export const RECORD_STATUS_DELETED = "DL";
export const RECORD_STATUS_INITIALIZING = "IN";

// Collection Collapsible Header Field Styles
export const FIELD_STYLE_BOLD = "bold";
export const FIELD_STYLE_ITALIC = "italic";
export const FIELD_STYLE_SMALL = "small";
export const FIELD_STYLE_GRAY = "gray";
export const FIELD_STYLE_CHECKBOX = "checkbox";
export const FIELD_STYLE_TRAILING = "trailing";

// Section Adornments
export const SECTION_ADORNMENT_REVIEW = "review";
export const SECTION_ADORNMENT_STATS = "stats";

// Report `eval_type`
// This is created dynamically using `{classification}_{designation}_REPORT`
// Currently, only used for Eval Search
export const EVAL_TYPE_PRO_HIT = `PRO_${DESIGNATION_HIT}_REPORT`;
export const EVAL_TYPE_PRO_PITCH = `PRO_${DESIGNATION_PITCH}_REPORT`;
export const REPORT_EVAL_TYPES = [EVAL_TYPE_PRO_HIT, EVAL_TYPE_PRO_PITCH];
